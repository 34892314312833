.headline {
    text-align: left;
    font-size: 24px;
    font-weight: 700;
    padding: 20px;
    color: #000000cc;
}

.settingsIcon {
    position: absolute;
    top: 28px;
    right: 20px;
}

.wheelOfFortune {
    text-align: center;
    position: relative;
    margin-top: 20px;
    color: #000000cc;
}

.controls {
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
}

@media (max-width: 768px) {
    .controls {
        display: block;
        gap: 20px;
        flex-wrap: wrap;
    }
}

.checkbox {
    margin: 10px 10px;
    position: relative;
    padding-left: 35px;
    font-size: 18px;
    user-select: none;
}

.checkbox input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 5px;
}

.checkmark:hover {
    cursor: pointer;
}

.checkbox:hover input ~ .checkmark {
    background-color: #ccc;
}

.checkbox input:checked ~ .checkmark {
    background-color: #000000cc;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox input:checked ~ .checkmark:after {
    display: block;
}

.checkbox .checkmark:after {
    left: 7px;
    top: 4px;
    width: 4px;
    height: 7px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

.options-container {
    overflow: hidden;
    margin: 0 auto;
    position: relative;
}

.options {
    display: flex;
    white-space: nowrap;
    transition: transform 0.3s ease-out;
    position: relative;
}

.option {
    padding: 10px;
    margin: 5px 5px;
    background: #fff;
    border: 1px solid #ccc;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    width: 200px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    color: rgba(0, 0, 0, 0.500);
    font-weight: 700;
}

.option img {
    height: 200px;
    width: 200px;
    margin-bottom: 10px;
    border-radius: 10px;
    object-fit: cover;
}

button {
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #000000cc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
}

.spinButton {
    padding: 40px 40px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    margin-top: 15px;
    background-color: rgba(110, 0, 250, 0.8);
}

button:disabled {
    background-color: #aaa;
    cursor: not-allowed;
}

button:hover:not(:disabled) {
    background-color: #000000cc;
}

button:active:not(:disabled) {
    transform: scale(0.95);
}

.needle {
    width: 3px;
    height: 100%;
    background-color: rgba(110, 0, 250, 0.8);
    position: absolute;
    top: 0;
    left: 50%;
    height: 263px;
    transform: translateX(-50%);
    z-index: 10;
    box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.300);
}

.selected-option {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 5px 20px;
    margin: 20px auto;
    display: flex;
    align-items: baseline;
    width: 80%;
    max-width: 500px;
}

.selected-option h2 {
    color: #333;
    margin: 0 0 10px;
    font-size: 1.5rem;
}

.option1 {
    font-size: 24px;
    font-weight: 700;
    color: #000000cc;
}

.option2{
    font-size: 14px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.300);
    margin-left: 5px;
    letter-spacing: -0.5px;

}



.option3{
    font-size: 14px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.300);
    margin-left: 5px;
}

.line {
    height: 2px;
    width: 100%;
    background-color: #000000cc;
    margin: 10px 0;
}

.selected-option h2:last-child {
    color: #666;
    font-size: 1rem;
}

.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 500px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #000000cc;
    text-decoration: none;
    cursor: pointer;
}

.players {
    margin-top: 0px;
    margin-bottom: 40px;
    text-align: center;
}

.player {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 10px auto;
    color: rgba(0, 0, 0, 0.300);
    max-width: 400px;
}

.player button {
    margin: 0 8px;
    padding: 5px 10px;
    font-size: 16px;
}

.player input {
    width: 150px;
    padding: 5px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    border-color: #0000005d;
    background-color: #0000003b;
    margin-right: 10px;
    transition: border-color 0.3s, box-shadow 0.3s;
}

.player input:focus {
    border-color: #0000005d;
    background-color: #0000003b;
    outline: none;
}


.player .MuiSvgIcon-root {
    cursor: pointer;
    margin-left: 10px;
    transition: color 0.3s;
}

.player .MuiSvgIcon-root:hover {
    color: #000000cc;
}

.current-player {
    color: #000000cc

}

.settingsIcon .MuiSvgIcon-root{
    color: #000000cc
}

.resetButton {
  padding: 10px 10px;
  font-size: 12px;
  color: #c40000c4;
  font-weight: 700;
  background-color: #ff000000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0);
  border: none;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.resetButton:hover:not(:disabled) {
  background-color: #ff000000;
}

.resetButton:active:not(:disabled) {
  transform: scale(1);
}
